export default class Currency {
    public static currencyToStringNumber(currencyValue: any): string {
        if (!currencyValue) return '';
        if (typeof (currencyValue) === 'number') return currencyValue.toString();
        const numberValue = parseInt(currencyValue.replace(/[^\d.-]/g, ''));
        return isNaN(numberValue) ? '' : numberValue.toString()
    }

    public static currencyToNumber(currencyValue: any): number | null {
        if (currencyValue == null) return null;
        if (typeof (currencyValue) === 'number')
            return currencyValue;
        const numberValue = parseInt(currencyValue.replace(/[^\d.-]/g, ''));
        return isNaN(numberValue) ? 0 : numberValue;
    }

    public static numberToCurrency(value: any | undefined, minDigits = 2, maxDigits = 2): string | null {
        if (typeof value === 'string') {
            value = this.currencyToNumber(value);
        }
        if (value == null || value === '')  return null;

        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: minDigits,
            maximumFractionDigits: maxDigits,
        }).format(value);
    }

    public static numberToCurrencyWithFormat(value: number | string | undefined, minDigits = 2, maxDigits = 2): string | undefined {
        if (value == null || value === '') return undefined;

        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: minDigits,
            maximumFractionDigits: maxDigits,
        }).format(Number(value));
    }
}
